import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable react/jsx-props-no-spreading */
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import CardSelect from '@shared/ui/misc/CardSelect';
import SidebarInner from '@shared/ui/sidebars/SidebarInner';
import { ContainerRow } from '@components/styled';
import Button from '@shared/ui/buttons/Button';
import Input from '@shared/ui/inputs/Input';
import InputMasked from '@shared/ui/inputs/InputMasked';
import { MASK_CARD_NUMBER, MASK_CVV, MASK_EXPIRE_DATE } from '@app/constants/validation';
import BackLinkButton from '@shared/ui/buttons/BackLinkButton';
import { StepFormContainer, StepText, Title } from '@widgets/sidebars/shared';
import PaymentSystem from '@entities/payment-system';
import { useWizard } from '@shared/ui/misc/Wizard';
import { BalanceManagementSteps, } from '../../../types';
import { cardDetailsValidationSchema } from '../../../validation';
const CardDetailsContainer = styled(ContainerRow)();
const InputContainer = styled.div(props => ({
    width: '100%',
    marginBottom: props.theme.spacing_sizes.m,
}));
const InputWithValidationContainer = styled(InputContainer, { shouldForwardProp: propName => propName !== 'haveInputErrors' })(props => ({
    height: 'auto',
    marginBottom: props.haveInputErrors ?
        props.theme.spacing_sizes.xs * 1.5 : props.theme.spacing_sizes.xs * 4.75,
}));
const InputsRow = styled(ContainerRow)(props => ({
    gap: props.theme.spacing_sizes.l,
}));
const CardDetails = ({ onCardDetailsFormSubmit, onSidebarClose, }) => {
    const { t } = useTranslation();
    const { goTo } = useWizard();
    const { control, handleSubmit, formState: { errors, }, } = useForm({
        defaultValues: {
            cardType: PaymentSystem.Visa,
            name: '',
            cardNumber: '',
            expireDate: '',
            cvv: '',
        },
        resolver: yupResolver(cardDetailsValidationSchema),
    });
    const haveNameErrors = typeof errors.name !== 'undefined';
    const haveCardNumberErrors = typeof errors.cardNumber !== 'undefined';
    const haveExpireDateErrors = typeof errors.expireDate !== 'undefined';
    const haveCvvErrors = typeof errors.cvv !== 'undefined';
    const onSubmit = (values) => {
        onCardDetailsFormSubmit(values);
        goTo(BalanceManagementSteps.CalculationProcess);
    };
    const goToPrevStep = () => {
        goTo(BalanceManagementSteps.ChoosePaymentMethod);
    };
    return (_jsx(SidebarInner, { title: (_jsx(BackLinkButton, { onClick: goToPrevStep, children: t('overview.balance_management.back_btn') })), padding: '50px 30px', cancelBtnProps: {
            onClick: onSidebarClose,
        }, displayCancelBtn: true, children: _jsx(CardDetailsContainer, { children: _jsx(StepFormContainer, { title: _jsx(Title, { children: t('overview.balance_management.card_details.title') }), onSubmit: handleSubmit(onSubmit), content: (_jsxs(_Fragment, { children: [_jsx(StepText, { stepNumber: t('overview.balance_management.card_details.step'), text: t('overview.balance_management.card_details.text') }), _jsx(InputContainer, { children: _jsx(Controller, { name: 'cardType', control: control, render: ({ field }) => (_jsx(CardSelect, { value: field.value, onChange: field.onChange, label: t('overview.balance_management.card_details.card_type.label') })) }) }), _jsx(InputWithValidationContainer, { haveInputErrors: haveNameErrors, children: _jsx(Controller, { name: 'name', control: control, render: ({ field, fieldState }) => {
                                    var _a, _b;
                                    return (_jsx(Input, Object.assign({}, field, { autoComplete: 'cc-name', label: t('overview.balance_management.card_details.name.label'), placeholder: t('overview.balance_management.card_details.name.placeholder'), notification: (_a = fieldState.error) === null || _a === void 0 ? void 0 : _a.message, notificationStatus: ((_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message) ? 'error' : undefined, InputLabelProps: {
                                            shrink: true,
                                        }, required: true })));
                                } }) }), _jsx(InputWithValidationContainer, { haveInputErrors: haveCardNumberErrors, children: _jsx(Controller, { name: 'cardNumber', control: control, render: ({ field, fieldState }) => {
                                    var _a, _b;
                                    return (_jsx(InputMasked, Object.assign({}, field, { autoComplete: 'cc-number', label: t('overview.balance_management.card_details.card_number.label'), placeholder: t('overview.balance_management.card_details.card_number.placeholder'), mask: MASK_CARD_NUMBER, notification: (_a = fieldState.error) === null || _a === void 0 ? void 0 : _a.message, notificationStatus: ((_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message) ? 'error' : undefined, required: true, InputLabelProps: {
                                            shrink: true,
                                        } })));
                                } }) }), _jsxs(InputsRow, { children: [_jsx(InputWithValidationContainer, { haveInputErrors: haveExpireDateErrors, children: _jsx(Controller, { name: 'expireDate', control: control, render: ({ field, fieldState }) => {
                                            var _a, _b;
                                            return (_jsx(InputMasked, Object.assign({}, field, { autoComplete: 'cc-exp', label: t('overview.balance_management.card_details.expire_date.label'), placeholder: t('overview.balance_management.card_details.expire_date.placeholder'), mask: MASK_EXPIRE_DATE, notification: (_a = fieldState.error) === null || _a === void 0 ? void 0 : _a.message, notificationStatus: ((_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message) ? 'error' : undefined, InputLabelProps: {
                                                    shrink: true,
                                                }, required: true })));
                                        } }) }), _jsx(InputWithValidationContainer, { haveInputErrors: haveCvvErrors, children: _jsx(Controller, { name: 'cvv', control: control, render: ({ field, fieldState }) => {
                                            var _a, _b;
                                            return (_jsx(InputMasked, Object.assign({}, field, { autoComplete: 'cc-csc', label: t('overview.balance_management.card_details.cvv.label'), placeholder: t('overview.balance_management.card_details.cvv.placeholder'), mask: MASK_CVV, notification: (_a = fieldState.error) === null || _a === void 0 ? void 0 : _a.message, notificationStatus: ((_b = fieldState.error) === null || _b === void 0 ? void 0 : _b.message) ? 'error' : undefined, InputLabelProps: {
                                                    shrink: true,
                                                }, hidden: true, required: true })));
                                        } }) })] })] })), contentFlex: 1, containerGap: 60, buttons: (_jsx(Button, { type: 'submit', size: 'large', fullWidth: true, children: t('overview.balance_management.card_details.continue_btn') })) }) }) }));
};
export default CardDetails;
